import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ApplicationHamaphone from "../components/applicationHamaphone";
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}

const tableStyle = {
    borderCollapse: "collapse",
    border: "solid 4px #333"
}
const thtdStyle = {
    border: "solid 2px #333"
}

const imgStyle = {
    borderRadius: "5%"
}
const logo = {
    margin: "50px auto"
}

const HamaphonePage = () => (
    <Layout>
        <Seo title="＃PBX"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="wigeta_pbx">＃PBX</h1>

            <p></p>
            <div>
                <p>カスタマイズして利用できるクラウドPBX</p>
                <p>お客様が販売するクラウドPBXとして提供可能です</p>
            </div>
            <div style={logo}>
                <StaticImage src="../images/20230423a_wigeta_pbx_logo.png"
                             alt={"＃pbx"}
                             style={imgStyle}
                             width={250}/>
            </div>


            <hr style={hrStyle}/>
            <div>
                <h2>特徴</h2>
                <div>
                    <p>クラウド型のビジネスフォンで、オフィスでも家でも外出先からでも会社の電話番号が利用できます。</p>
                    <p>PC、スマートフォンに対応したアプリでどこからでも使えます。</p>
                    <p>ロゴやデザイン、機能をお客様に合わせて変更できます。</p>
                </div>
                <StaticImage src="../images/products/wigeta_pbx/screenshot2.png"
                             alt={"Screenshot"}
                             style={imgStyle}
                             width={750}/>
            </div>
            <hr style={hrStyle}/>

            <div>
                <h2>料金</h2>
                <h3>プラン</h3>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="65%"/>
                        <col width="35%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td>１～3内線</td>
                        <td style={leftTdStyle}>月額 1,900円+税～</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td>4内線～</td>
                        <td style={leftTdStyle}>月額 900円/内線+税</td>
                    </tr>

                </table>
                <h3>オプション</h3>
                <p>下記に記載以外のご要望でもお気軽に問い合わせください。</p>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="65%"/>
                        <col width="35%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>電話番号追加</td>
                        <td style={leftTdStyle}>月額 500円+税</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>各種設定変更手数料</td>
                        <td style={leftTdStyle}>2,000円+税</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>新規申込手数料</td>
                        <td style={leftTdStyle}><font color={"red"}>0円</font></td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>Windows用ソフトフォン作成</td>
                        <td style={leftTdStyle}>初期費用 100,000円+税<br/>月額費用 10,000円+税</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>カスタマイズ</td>
                        <td style={leftTdStyle}>※お問い合わせください</td>
                    </tr>
                </table>
                <h3>通話料</h3>
                <div>
                    <p>※弊社が提供するSIPトランクでの料金になります。お客様がご用意する回線への接続も可能です。</p>
                </div>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="65%"/>
                        <col width="35%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>内線</td>
                        <td style={leftTdStyle}>0円</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>市内</td>
                        <td style={leftTdStyle}>3.8円/1分+税</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>市外</td>
                        <td style={leftTdStyle}>3.8円/1分+税</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>携帯</td>
                        <td style={leftTdStyle}>15.9円/1分+税</td>
                    </tr>
                </table>
            </div>


            <hr style={hrStyle}/>

            <div>
                <h2>Windows用ソフトフォン</h2>
                <p>ロゴや設定項目などがカスタマイズ可能です。※画像はサンプルです</p>
                <StaticImage src="../images/products/hamaphone/softphone1.png"
                             alt={"はまふぉんソフトフォン"}
                             style={imgStyle}
                             width={300}/>
                <p>
                    <a href="https://www.ringing.co.jp/download/hamaphone_softphone-3.20.7.exe">◆Download◆</a>
                    <p>※ Windows10/11対応</p>
                </p>
            </div>
            <hr style={hrStyle}/>
            <p>
                <a href="https://www.ringing.co.jp/pdf/＃PBX利用規約.pdf">利用規約</a>
            </p>
            <hr style={hrStyle}/>
            <div>
                <p>まずは無料デモをご案内いたします。こちらからお申込みください。</p>
            </div>
            <ContactUs/>
        </div>
    </Layout>
)

export default HamaphonePage
